import { Link } from 'react-router-dom';
import './ButtonLinkSecondary.css';

export default function ButtonLinkSecondary({ children, data, path }) {
  return (
    <Link
      className="button-link-secondary"
      to={{
        pathname: `${path}`,
        state: data,
      }}
    >
      {children}
    </Link>
  );
}
