import Text from '../../components/text/Text';
import H1 from '../../components/h1/H1';
import H3 from '../../components/h3/H3';
import './Portfolio.css';
import portfolioData from './portfolioData';
import { Link } from 'react-router-dom';
import SocialMediaButtons from 'react-social-media-buttons';
import '../../components/button-link/ButtonLink.css';
import ButtonLinkSecondary from '../../components/button-link-secondary/ButtonLinkSecondary';


export default function Portfolio() {
  return (
    <div>
      <div className="masthead">
        <H1>Hi 👋🏼 I'm Ramzi </H1>
        <Text center>
          I'm a full-stack, growth-mindset-focused product designer and
          strategist currently at Bubble, and previously at HubSpot. I
          have led product development teams from discovery through delivery, within the
          SMB and enterprise space, focusing on connecting the dots between business goals,
          user needs, and innovation.
        </Text>
        <div className="online-container">
          <a href="mailto:nahawi.ramzi@gmail.com?" target="_newtab">
            <img className="online-image" src="online.png" alt="onlinestatus" />
          </a>
        </div>
      </div>
 
        <div className="box-container">
  {portfolioData.map((item, idx) => (
    item.isExternal ? (
      <a href={item.path} className="box" key={idx} target="_blank" rel="noopener noreferrer">
        <img
          className={
            idx % 2 === 0
              ? 'box-content box-image'
              : 'box-content box-image box-content-right'
          }
          src={item.image}
          alt={item.title}
        />
        <div className="box-content">
          <H3>{item.title}</H3>
          <Text>{item.subTitle}</Text>
        </div>
      </a>
    ) : (
      <Link to={item.path} className="box" key={idx}>
        <img
          className={
            idx % 2 === 0
              ? 'box-content box-image'
              : 'box-content box-image box-content-right'
          }
          src={item.image}
          alt={item.title}
        />
        <div className="box-content">
          <H3>{item.title}</H3>
          <Text>{item.subTitle}</Text>
        </div>
      </Link>
    )
  ))}
</div>
</div>

  );
}
