import { useContext } from 'react';
import { DarkMode } from '../../App';
import './DarkModeButton.css';

function DarkModeButton({ mobile }) {
  const { darkMode, setDarkMode } = useContext(DarkMode);

  function handleDarkMode() {
    setDarkMode(!darkMode);
    document.body.classList.toggle('dark-mode');
  }

  return (
    <button
      className={`dark-mode-button ${darkMode ? 'dark' : ''} ${
        mobile ? 'dark-mode-mobile' : 'dark-mode-desktop'
      }`}
      onClick={handleDarkMode}
    >
      {darkMode ? 'Turn Off Dark Mode ☀️' : 'Turn On Dark Mode 🌙'}
    </button>
  );
}

export default DarkModeButton;
