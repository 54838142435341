import Text from '../../components/text/Text';
import H2 from '../../components/h2/H2';
import H4 from '../../components/h4/H4';
import './About.css';
import aboutFacts from './aboutFacts';
import aboutPeople from './aboutPeople';

import '../../components/button-link/ButtonLink.css';

export default function About() {
  return (
    <div>
      <div className="masthead">
        <img className="about-image" src="about_ramzi.png" alt="About Ramzi" />
        <H2>So you want to learn more about me?</H2>
        <Text>Here are four fun facts about me</Text>
      </div>

      <div className="flex-container">
        {aboutFacts.map((fact, idx) => (
          <div className="flex-box" key={idx}>
            <img className="flex-image" src={fact.imgSrc} alt={fact.imgAlt} />
            <div style={{ paddingTop: '1rem' }} />
            <Text center>{fact.description}</Text>
          </div>
        ))}
      </div>

      <H2>What people are saying about me</H2>
      <div className="grid-wrap">
        {aboutPeople.map((person, idx) => (
          <div className="flex-box rec-box" key={idx}>
            <div className="rec-box-top">
              <img
                className="flex-image"
                src={person.imgSrc}
                alt={person.name}
              />
              <div className="rec-titles">
                <H4>{person.name}</H4>
                <Text small noMargin>
                  {person.title}
                </Text>
              </div>
            </div>
            <Text>{person.description}</Text>
          </div>
        ))}
      </div>

      <div className="online-container">
        <a href="https://meetings.hubspot.com/rnahawi/one-on-one-meeting?" target="_newtab">
          <img className="online-image" src="online.png" alt="onlinestatus" />
        </a>
      </div>
    </div>
  );
}
