import './Text.css';

export default function Text({ children, center, small, noMargin }) {
  return (
    <p
      className={`body-text ${noMargin ? 'no-margin' : ''}`}
      style={{
        textAlign: center ? 'center' : 'left',
        fontSize: small ? '12px' : '16px',
      }}
    >
      {children}
    </p>
  );
}
