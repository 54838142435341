import './Detail.css';
import H1 from '../../components/h1/H1';
import H2 from '../../components/h2/H2';
import Text from '../../components/text/Text';
import ButtonLinkSecondary from '../../components/button-link-secondary/ButtonLinkSecondary';

export default function UsCopyright() {
  return (
    <div className="detail-container">
      <H1>U.S. Copyright Office Modernization</H1>

      <img
        className="detail-image"
        src="https://pro2-bar-s3-cdn-cf1.myportfolio.com/4a4e2aff25b75908adf055a5e9feb9f7/9504f2ef-3768-4007-8135-6519ca55b837_rw_1200.png?h=cb480de166c6ec8c50bd64f472a2cff4"
        alt="US Copyright 1"
      />

      <H2 left>Overview</H2>
      <Text>
        Being part of Accenture's Design and Innovation Studio, I had the
        opportunity to lead and partner with a 5-person team to reimagine the
        U.S Copyright Office's registration and examination web-application. For
        this particular engagement, we used the double-diamond approach to go
        from discovery through delivery of an MVP build. I also supported
        development for two additional releases - and successfully on-boarded
        over 1,100 users onto the new platform with 94% user acceptance and
        satisfaction (based on quantitative and qualitative user metrics and
        feedback).
      </Text>
      <Text>
        My role primarily focused on creating interactive prototypes using
        Sketch, inVision, and Principle (for motion), presenting designs to a
        large group of stakeholders, facilitating visioning and ideation
        workshops, leading the creation of an atomic design system, working
        closely with the design team on defining the information architecture,
        creating user flows and journeys, collaborating with product owners and
        engineering on the roadmap, and writing detailed user requirements and
        stories in JIRA on an ongoing basis. (epics, roles, and stories).
      </Text>

      <img
        className="detail-image"
        src="https://pro2-bar-s3-cdn-cf1.myportfolio.com/4a4e2aff25b75908adf055a5e9feb9f7/c2d92cdd-d5aa-4fe9-aeb6-3fa875427a13_rw_1920.png?h=ef3e2fcbfb48e5dd4a2cf9c0a268380b"
        alt="US Copyright 2"
      />

      <H2 left>Discovery Phase</H2>
      <Text>
        At the beginning of the project, we focused our efforts on adopting as
        much divergent thinking as possible to digest as much information as
        possible about the current state of the U.S Copyright Office's
        registration and examination processes. We were able to recruit over 35
        candidates for user interviews and determined the criteria for what
        types of users we wanted to speak with. We were able to speak with
        Examiners, Supervisors, and Managers, all of which work within the U.S
        Copyright Office.
      </Text>
      <Text>
        I facilitated two internal workshops with my team to make sure we were
        asking the right questions. I proposed we use activities such as round
        robin exercises, heuristic evaluations, creative matrices, and
        contextual inquiries to make sure we were capturing the right
        information from end-users, and to identify gaps in the user experience
        of the current system. We synthesized the feedback as a team over the
        course of two weeks, and then prepared a final readout presentation to
        present to stakeholders.
      </Text>

      <img
        className="detail-image"
        src="https://pro2-bar-s3-cdn-cf6.myportfolio.com/4a4e2aff25b75908adf055a5e9feb9f7/acfd1bd3-5052-43fd-9df5-aada8c362a9e_rw_1920.png?h=1414a79af8dcf65b23152db813f5d29d"
        alt="US Copyright 3"
      />

      <H2 left>What We Heard</H2>
      <Text>
        Since Copyright Examiners, Supervisors, and Managers have substantailly different
        roles within the Office, we heard a lot of varying feedback and concerns about
        the current system in place: Siebel. For starters, Examiners were mostly concerned
        with the need to come up with workarounds to make the application examination process more
        efficient. Supervisors were lacking a birds-eye-view of what and how
        their teams were doing, and Managers didn't have the ability to view
        analytics and reports.{' '}
      </Text>

      <img
        className="detail-image"
        src="https://pro2-bar-s3-cdn-cf2.myportfolio.com/4a4e2aff25b75908adf055a5e9feb9f7/dcd340f1-02e3-470b-81c5-5c9146198e36_rw_1920.png?h=c3f0c5467630f92fe284c62a8e231a32"
        alt="US Copyright 4"
      />

      <H2 left>Observations</H2>
      <Text>
        After reviewing our notes, insights, and learnings from the user
        interviews, contextual inquiries, and job shadowing efforts, we
        synthesized our findings into themes and observations. I lead the
        research readout session in which we revealed these key observations to
        the client, and had an open discussion with them about next steps with
        regards to launching into ideation from these key themes.
      </Text>
      <Text>
        It was critical that we place importance on these themes being the
        pillars that would help launch our ideation sessions, as this would
        guarantee that our product creation and design was in fact derived from
        a human-centered, research and insights-driven approach.
      </Text>

      <img
        className="detail-image"
        src="https://pro2-bar-s3-cdn-cf6.myportfolio.com/4a4e2aff25b75908adf055a5e9feb9f7/75b2c324-2dd2-4501-aea3-d014175bd811_rw_1920.png?h=72bf4d77c96b764db5f4b30e64b3ca12"
        alt="US Copyright 5"
      />

      <H2 left>Design Opportunities</H2>
      <Text>
        After we completed synthesis, we moved into the definition phase of the
        double diamond approach by using convergent thinking to define a set of
        design opportunities that we would use to create the initial wireframes
        during the development phase of the project. We worked closely with the 
        three user personas to triangulate our assumptions and iterate on our initial
        concepts. We were fortunate that we had the opportunity to work onsite, which
        meant we were able to get feedback early and often from users.

      </Text>

      <img
        className="detail-image"
        src="https://pro2-bar-s3-cdn-cf4.myportfolio.com/4a4e2aff25b75908adf055a5e9feb9f7/5f19a7dc-d134-453a-9775-93bbe38eb0e2_rw_1200.png?h=8842eb4037f0da3421b265ead86a09e2"
        alt="US Copyright 6"
      />

      <img
        className="detail-image"
        src="https://pro2-bar-s3-cdn-cf4.myportfolio.com/4a4e2aff25b75908adf055a5e9feb9f7/f2510e36-e944-4fbb-82bc-96e9bfb6d105_rw_1920.png?h=9d5ef5a8c38add6408bb2fc178828831"
        alt="US Copyright 7"
      />

      <H2 left>Initial Design Phase</H2>
      <Text>
        After presenting our design opportunities and recommendations, we moved
        into to the development phase by running design studio sessions where
        the team and I came up with several design approaches to address all of
        the design opportunities presented earlier in the project. We
        purposefully kept our design ideas in low fidelity, using pencil, paper,
        sticky notes, and white-boards in an effort to be nimble and iterative
        in our approach. Below you'll find the refined low fidelity wireframes
        that we reviewed and tested with end-users and stakeholders.
      </Text>
      <Text>
        We went through several rounds of collecting feedback and iterating in
        low-fidelity before beginning to apply styles and color to our designs.
        We scheduled 16 weekly review sessions with end-users throughout this
        process to ensure that we captured as much feedback as possible in the
        early phases of development. This enabled us to move fast and iterate
        quickly.{' '}
      </Text>

      <img
        className="detail-image"
        src="https://pro2-bar-s3-cdn-cf3.myportfolio.com/4a4e2aff25b75908adf055a5e9feb9f7/ed42d8e1-38ff-4c87-a18c-584543ca25fc_rw_1920.png?h=c6a2b2d0ec1980df12b73bc3ff196f94"
        alt="US Copyright 8"
      />


      <H2 left>Atomic Design System Creation & Delivery</H2>
      <Text>
        In addition to building user-specific prototypes, I partnered with a Visual Designer to create
        an atomic design system that USCO utilized for two other systems that
        would involve registration and transfer of ownership. These two systems
        would eventually integrate with ECS (the system we designed), to ensure
        a streamlined experience for examiners and applicants. Part of creating
        a streamlined experience for multiple user roles relies upon the visual
        language being scalable and consistent.
      </Text>
      <Text>
        We were able to achieve scalability and consistency by leveraging atom
        components from the U.S Digital Services Design System, and then build
        organisms and page templates based on use-case and user scenarios - this
        proved to be very effective for the delivery team. I delivered this
        design system by providing a cohesive design library that included
        components (atoms, organisms, and page templates), typography, layouts,
        and colors, but also provided documentation to further explain usage and
        governance of the design system.
      </Text>

      <img
        className="detail-image"
        src="https://pro2-bar-s3-cdn-cf1.myportfolio.com/4a4e2aff25b75908adf055a5e9feb9f7/c8cbf5ff-4d4f-4116-bb64-ddcc92775aa6_rw_1920.png?h=ae3abfdb89da8aca8a61982f52f56c3f"
        alt="US Copyright 10"
      />

      <H2 left>Creating An Easy-To-Use PDK</H2>
      <Text>
        Part of the challenge with creating a design system in conjunction with
        designing a new system is that components and interactions will evolve
        as you continue to test and validate user workflows. This was definitely
        the case with this particular product, and one of the key learnings was
        that having proper governance of a design system is critical in early
        phases of product creation.
      </Text>
      <Text>
        My strategy to keep the design system and wireframes up to date was to
        ensure that any updates of components were documented and communicated
        immediately with the rest of the team so that no ideas are lost or
        broken due to design system updates. Building the PDK (product design
        kit) in Sketch allowed for this streamlined approach, and made it easy
        for myself and the rest of the team to be fairly consistent when
        exploring new ideas with regards to ECS.{' '}
      </Text>

      <img
        className="detail-image"
        src="https://pro2-bar-s3-cdn-cf1.myportfolio.com/4a4e2aff25b75908adf055a5e9feb9f7/9eb17a52-4149-4365-8508-23fc4c0cd2d4_rw_1920.png?h=19dbf5e3bf0fc2df8e5f37d2302cf64d"
        alt="US Copyright 11"
      />

      <img
        className="detail-image"
        src="https://pro2-bar-s3-cdn-cf1.myportfolio.com/4a4e2aff25b75908adf055a5e9feb9f7/91141a1f-091f-4f11-ac08-88556045bacd_rw_1920.png?h=401eda63c3c736a92989812e38091628"
        alt="US Copyright 12"
      />

      <H2 left>Delivery Phase and Final Thoughts</H2>
      <Text>
        The deliverables within this engagement included three interactive
        prototypes for each user role (Examiner, Supervisor, and Manager), a
        cohesive and usable atomic design system that the internal team could
        use to build upon our wireframes, user stories that provided depth and
        business logic to all of the wireframes and interactions within each
        unique workflow, documentation of all research, our findings and
        synthesis, and annotated wireframes of primary workflows to help
        development get started.{' '}
      </Text>
      <Text>
        Once our project was complete, the client requested we begin a new
        contract with them that would include a build out of the MVP, as well as
        roadmap refinement for Post-MVP phases. I am currently the Product
        Design Lead on this new engagement, where I am using my deep expertise
        in the delivery phase of digital product creation to work closely with
        our internal development team to build the MVP, and work with product
        and leadership on backlog refinement.
      </Text>

      <H2 left>Phase 2.0 User Analytics</H2>
      <Text>
        As part of Phase 2 of this particular engagement, I have set up various
        user analytics tools to capture users' behaviors in an effort to
        continually improve the usability of the platform. I am currently using
        Mouseflow, Pendo and Google Analytics to track and run experiments on
        several user workflows and pages of the platform.
      </Text>
      <Text>
        On the surface level, I am tracking basic metrics such as drop-off
        rates, time spent on a given page, and time to complete particular tasks
        that I have built out in Mouseflow (using funnels). On a deeper level, I
        am running A/B tests on the claims queue and detail page layouts to see
        which are more optimal for the different user personas or roles. I am
        currently working on the 1.3.0 release alongside the development team -
        focusing on feature enhancement and ideation, 508-compliance testing,
        conducting QA of the UI, usability and user testing, and contributing to
        backlog refinement and sprint planning sessions.{' '}
      </Text>
      <Text>
        I am currently the lead designer embedded on a design, product, and
        engineering triad, supporting the current release, as well as future
        releases using a framework that ensures that we are adopting an
        iterative and customer-focused design approach.
      </Text>
<Text>       </Text>
      <div className="button-center">
        <ButtonLinkSecondary path="/">Back to all work</ButtonLinkSecondary>
      </div>
    </div>
  );
}
