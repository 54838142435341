const aboutFacts = [
  {
    imgSrc: 'guitar.png',
    imgAlt: 'Netflix',
    description:
      'I co-produced a song that was featured in a Netflix original movie.',
  },
  {
    imgSrc: 'weights.png',
    imgAlt: 'Teach boxing',
    description: 'I have competed in several Brazilian Jiu-Jitsu tournaments.',
  },
  {
    imgSrc: 'mic.png',
    imgAlt: 'The Voice',
    description:
      'I auditioned for the Voice "Season 13" and got through three rounds of casting.',
  },
  {
    imgSrc: 'bulb.png',
    imgAlt: '$2m Pitch Deck',
    description:
      "I designed a pitch deck that led to a $2m partnership with Morgan Stanley's Multicultural Innovation Lab.",
  },
];

export default aboutFacts;
