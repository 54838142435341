import './Detail.css';
import H1 from '../../components/h1/H1';
import H2 from '../../components/h2/H2';
import Text from '../../components/text/Text';
import ButtonLink from '../../components/button-link/ButtonLink';
import ButtonLinkSecondary from '../../components/button-link-secondary/ButtonLinkSecondary';
import { Link } from 'react-router-dom';

export default function Bubble () {
  return (
    <div className="detail-container">
      <H1>Reimagining Version Control</H1>
      <img className="detail-image" src="hubspot2.png" alt="image" />

      <H2 left>Overview</H2>

      <Text>
        For the past year, XYZ
      </Text>

      <H2 left>Problem</H2>

      <Text>
       XYZ
      </Text>

      <Text>
       XYZ
      </Text>

      <Text>
        {' '}
      XYZ
      </Text>

      <H2 left>Opportunity</H2>

      <Text>
      XYZ
      </Text>
      <Text>
      XYZ
      </Text>
      <H2 left>What is a Data-Driven Website?</H2>

      <Text>
    XYZ
      </Text>

      <Text>
     XYZ
      </Text>

      <H2 left>Current State</H2>
      <Text>
      XYZ
      </Text>
   

      <H2 left> Future State</H2>

      <Text>
        As a unified data platform, we have a unique opportunity to deliver an
        easy-to-use structured content solution that allows our customers to
        turn data into dynamically generated, personalized content in ways that
        few of our competitors could dream of. Allowing our customers to run
        their marketing, sales and service activities fully on the framework
        data will enable them to build sophisticated experiences for their
        customers, and extract more value from HubSpot as they grow.
        Additionally, we will enable developers to remove themselves as
        gatekeepers for non-technical users to create these experiences on their
        own, something which has never been possible before on CMS Hub.
      </Text>
    </div>
  );
}
