import { NavLink } from 'react-router-dom';
import './NavigationLink.css';

function NavigationLink({ link, className }) {
  return (
    <NavLink
      exact
      to={link.to}
      className={className}
      activeClassName="nav-active"
    >
      {link.display}
    </NavLink>
  );
}

export default NavigationLink;
