const portfolioData = [ 
  {
    title: 'Speaking at BubbleCon',
    subTitle: 'Bubble, 2024',
    path: 'https://www.youtube.com/watch?v=IT0AYjrgFZ8',
    image: "future.jpg",
    isExternal: true
  },

  {
    title: 'Building a Travel App on Bubble',
    subTitle: 'Vela Travel, 2024',
    path: 'http://velatravel.co',
    image: "vela_06.png",
    isExternal: true
  },

  {
    title: 'Reimagining Version Control',
    subTitle: 'Bubble, 2023',
    path: 'https://bubble.io/blog/new-version-control/',
    image: "vc_thumb_2.png",
    isExternal: true
  },
  
  {
    title: 'Custom Content Types',
    subTitle: 'HubSpot, 2022',
    path: 'hubspot',
    image: "hubspot.png",
    isExternal: false

  },

/*
  {
    title: 'U.S Copyright Modernization',
    subTitle: 'Fjord, 2018',
    path: 'uscopyright',
    image: "copyright1.png"
  },



  {
    title: 'No-Code Platform',
    subTitle: 'Hatch Apps, 2017',
    path: 'hatch',
    image: 'hatchapps4.png',
  },
*/

];

export default portfolioData;
