import './MobileNavigation.css';
import { useState } from 'react';
import NavigationLink from '../navigation-link/NavigationLink';

function MobileNavigation({ links }) {
  const [mobileNavVisible, setMobileNavVisible] = useState();

  function toggleMobileNavigation() {
    setMobileNavVisible(!mobileNavVisible);
  }

  return (
    <div className="mobile-navigation" onClick={toggleMobileNavigation}>
      ☰
      <div
        className={`mobile-nav-menu ${
          mobileNavVisible ? 'mobile-nav-menu-active' : ''
        }`}
      >
        <div className="mobile-nav-top">
          <img
            className="nav-logo mobile-nav-logo"
            src="logo.png"
            alt="Ramzi"
          />
          <button
            className="mobile-nav-closer"
            onClick={toggleMobileNavigation}
          >
            ✖️
          </button>
        </div>
        {links.map((link, idx) => (
          <NavigationLink className="mobile-link" key={idx} link={link} />
        ))}
      </div>
    </div>
  );
}

export default MobileNavigation;
