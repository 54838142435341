import NavigationLink from '../navigation-link/NavigationLink';
import MobileNavigation from '../mobile-navigation/MobileNavigation';
import './Navigation.css';
import DarkModeButton from '../dark-mode-button/DarkModeButton';

const navLinks = [
  {
    to: '/',
    display: 'Case Studies',
  },

];

function Navigation() {
  return (
    <nav className="navigation">
      <div className="desktop-nav-items">
        {navLinks.map((link, idx) => (
          <NavigationLink key={idx} link={link} />
        ))}
      </div>
      <div className="nav-container">
        <a href="/" target="_self">
          <img className="nav-logo" src="logo-ramzi.png" alt="navigationlogo" />
        </a>
      </div>{' '}
      <DarkModeButton />
      <MobileNavigation links={navLinks} />
    </nav>
  );
}

export default Navigation;
