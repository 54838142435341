import './Detail.css';
import H1 from '../../components/h1/H1';
import H2 from '../../components/h2/H2';
import Text from '../../components/text/Text';
import ButtonLinkSecondary from '../../components/button-link-secondary/ButtonLinkSecondary';

export default function Hatch() {
  return (
    <div className="detail-container">
      <H1>Hatch Apps</H1>
      <img className="detail-image" src="hatchapps.png" alt="Hatch 1" />

      <H2 left>Overview</H2>

      <Text>
        I was the first hire and Product Design Lead at Hatch Apps, where our
        mission was to enable global enterprise organizations to build, deploy,
        and manage custom apps with end-to-end control and transparency.
      </Text>
      <Text>
        Several companies have built digital platforms that lowered the
        barrier-of-entry (the need for technical-know-how) for customers to
        build powerful software without needing an engineering team.
        Squarespace, Wordpress, and Weebly have been able to capture a large
        market share of the website builder space - Shopify and Magento have
        followed a similar path in the e-commerce space. At Hatch Apps, our
        vision was to create a platform that would enable non-technical users to
        build and deploy enterprise-grade native mobile-applications at a
        fraction of the time and cost.
      </Text>
      <Text>
        We sought a future-state where we could be the marquee platform enabling
        our customers to build, customize, and deploy bespoke native
        mobile-applications using our easy-to-use drag-and-drop visual builder,
        and application management suite (user role management, content
        management, server and database management, integrations, engagement,
        and analytics).
      </Text>
      <Text>
        As the Design Lead, I worked very closely with leadership, product
        managers, and engineering team to craft the focal points of our product
        offerings, define the roadmap, and design the platform to meet the needs
        of customers who wanted to build, manage, customize, and deploy
        enterprise-grade mobile applications, at a fraction of the cost of
        traditional software development.
      </Text>

      <H2 left>Competitive Landscape</H2>

      <Text>
        Designing the platform and prioritizing the right mix of features for
        our MVP prompted us to conduct a deep analysis of no-code development
        platforms in the landscape. Companies like Outsystems, Appian, Bubble,
        and Ionic Builder were very well-positioned to offer a native mobile
        application visual builder and enterprise application management suite,
        but were still in the early-phases of creating these features at the
        time (early-2017). Our team took this as an opportunity to deliver a
        solution that uniquely solved for problems that our competitors weren't
        solving. Examples of this were: one-click deployment, an easy-to-use
        editor, pre-made templates for popular use cases, and a robust data
        management suite.
      </Text>

      <Text>
        {' '}
        One of our core learnings from our competitive analysis was that most
        no-code platforms exist on one side of the spectrum of either being a
        feature factory with bad UX, or a niche solution that solves one
        industry-specific problem really well. Our vision for the Hatch platform
        was to be in the middle of that spectrum: a solution that was
        frictionless enough to give non-technical founders a solid starting
        point with regards to building out their mobile application.
      </Text>
      <Text>
        {' '}
        We also made the decision to build a no-code solution, over a low-code
        solution. The benefit of pursuing no-code rather than low-code is that
        non-technical users are able to own the end-to-end process of
        application creation to deployment - this comes at the cost of depth of
        functionality which can be a challenge for enterprise organizations who
        need that extensibility, but our primary persona was the non-technical
        Start Up founder, and not large enterprise organizations, so this made
        our decision fairly easy.
      </Text>
      <img className="detail-image" src="nocodething.png" alt="Hatch 2" />

      <H2 left>Drag-and-Drop Visual Builder​​​​​​​</H2>
      <Text>
        In addition to running competitive analysis, I also conducted user
        interviews with customers, and validated my design decisions with a
        combination of moderated usability testing and by following UI best
        practices. After synthesizing my findings from user interviews with
        customers, I designed a higher fidelity prototype of the visual builder
        feature, and supported development through the build-out of beta 1.0.
      </Text>
      <Text>
        I came out of beta 1.0 with several learnings about the usability, user
        acceptance and satisfaction of the visual builder. I was able to measure
        the success of features using Pendo Analytics and set triggers on
        critical actions to see if users were engaging with them. An example of
        a critical action was observing when and if the user would interact with
        the "Publish" button.
      </Text>

      <img className="detail-image" src="visualbuilder.png" alt="Hatch 3" />
      <img className="detail-image" src="actualapp.png" alt="Hatch 4" />
      
      
  

      <H2 left>Pivoting to App Management</H2>

      <Text>
        The nature of working at an early-stage product company means that
        priorities and learnings will evolve on a daily basis. As I continued to
        observe our user base with both a quantitative and qualitative lens
        (speaking with customers), discussions arose with leadership and the
        product and engineering teams to shift the focus of our
        product offerings to expand upon our application management and
        deployment features rather than continuing to focus on application
        creation and design. I learned that resilience is an important attribute
        for designers to cultivate amidst the constant change that comes with
        working at an early-stage startup - I found it to be especially useful
        when I joined Accenture and was able to juggle multiple projects and
        multiple stakeholders needs.
      </Text>
      <Text>
        Below you'll find the next iteration of the Hatch Apps platform that I
        designed based on our discussions about pivoting to having more robust
        application management features. Along with the new features I designed,
        I created and owned the Hatch platform's design system that was used in
        the process of re-building our product.
      </Text>

      <img className="detail-image" src="newcms.png" alt="Hatch 6" />
      <img className="detail-image" src="teammanage.png" alt="Hatch 7" />
      <img className="detail-image" src="usermanage.png" alt="Hatch 8" />
      <img className="detail-image" src="engage.png" alt="Hatch 9" />
      <img className="detail-image" src="analytics.png" alt="Hatch 10" />

      <H2 left> Design System Creation</H2>

      <Text>
        I designed a comprehensive mobile app design system to enable our
        customers to build and design mobile user interfaces using our visual
        builder. These components could be customized based on a series of
        controls such as a color picker, size selection, style selection, and
        other more advanced configurations like border radius values.
      </Text>
      <Text>
        The Hatch Apps design system enabled our enterprise customers to build
        powerful mobile apps without having to be concerned about the details of
        product design. Our comprehensive approach of providing components that
        were device-agnostic (iOS and Android), meant that our customers could
        build ready-to-go workflows and deploy them to their end-users
        instantly, regardless of what type of device they were using.
      </Text>

      <img className="detail-image" src="hads2.png" alt="Hatch 12" />

      <H2 left>Usability Testing & Feedback</H2>

      <Text>
        The more clients I had the opportunity to engage with, test with, and
        learn from, the more opportunity there was for the visual builder to
        improve from a usability perspective. During my tenure at Hatch Apps, I
        worked very closely with our beta users to ensure we were addressing
        their needs, and building a simple and elegant tool that they could then
        leverage to build powerful applications for their end-users.
      </Text>

      <img className="detail-image" src="appslive.png" alt="Hatch 13" />

      <H2 left>Visual Design</H2>
      <Text>
        Beyond designing the user experience of the product, I also designed our
        customer-facing landing page as well as additional pages for Hatch's
        marketing website. I provided two design options for the landing page
        which we A/B tested to evaluate conversion rates. The navy blue
        background design had a 35% higher conversion rate after a 10-week
        analysis. This might have been the case because it was easier to read
        (higher contrast ratio).
      </Text>

      <img className="detail-image" src="marketingsite.png" alt="Hatch 14" />

      <div className="button-center">
        <ButtonLinkSecondary path="/">Back to all work</ButtonLinkSecondary>
      </div>
    </div>
  );
}
