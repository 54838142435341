import './Detail.css';
import H1 from '../../components/h1/H1';
import H2 from '../../components/h2/H2';
import Text from '../../components/text/Text';
import ButtonLinkSecondary from '../../components/button-link-secondary/ButtonLinkSecondary';
import H3 from '../../components/h3/H3';

export default function Hubs() {
  return (
    <div className="detail-container">
      <H1>Designing for Custom Types of Structured Content</H1>
      <img className="detail-image" src="hubspot2.png" alt="Hubspot 1" />

      <H2 left>Overview</H2>

      <Text>
        At HubSpot, I lead Product Design for two teams, the Data-Driven
        Content, and Structured Content Editing teams. Both of these teams'
        missions are to empower HubSpot customers to be able to create
        sophisticated, and personalized web experiences. Experiences that look
        and feel like Eventbrite, Zillow, or Netflix - where the content that
        the end-user is shown is relevant and personalized.{' '}
      </Text>

      <Text>
        {' '}
        In 2022, many of our CMS customers no longer want to build "brochure"
        websites with 4-5 website pages. They want to build unique,
        sophisticated, and personalized web experiences that convert leads and
        build trust amongst their audiences. They want to build connected
        customer experiences that no longer require a lot of time or effort from
        their customers - but our existing solutions don’t really solve this
        urgent need.
      </Text>

      <Text>
        A core example of this type of experience is one where there is a list
        of items, where each item has its own detail page - this is a
        foundational step to get to a world where these list of items could be
        personalized based on any CRM Contact's properties, which would open up
        a plethora of opportunities for HubSpot customers to build unique and
        personalized (gated or un-gated) web experiences.
      </Text>

      <img className="detail-image" src="example1.png" alt="Hubspot 3" />

      <H2 left>Problem</H2>

      <Text>
        {' '}
        Our existing solutions don't really provide a way for our customers to
        create sophisticated web experiences. To get more specific about what a
        sophisticated web experience looks like - let’s dig into an example:
      </Text>

      <Text>
        Meet Arda, a Content Marketer at Ventana, a non-profit organization that
        runs in-person and virtual events to grow their community and convert
        leads into donors. She wants to build a new page on the website that
        lists all of the events that she and her team are hosting over the
        coming months so that people can learn more, browse, and ultimately sign
        up for events they are interested in.
      </Text>

      <img className="detail-image" src="arda1.png" alt="Hubspot 2" />

      <Text>
        Unfortunately, there's no easy way for her to actually create these
        website pages and manage the events, other than by using workarounds, or
        by hiring a Developer or HubSpot partner. Interestingly enough, this
        isn’t just a problem that Arda has, usage data shows that 34% of our
        Blog customers use our Blog tool for use cases outside of a traditional
        Blog (Events, Case Studies, Knowledge Base Articles, and more).
      </Text>

      <H2 left>Arda Has Two (Subpar) Options</H2>

      <Text>
        a.) Use the Blog tool to create an Events-type listing - which is highly
        limiting and inflexible for use cases outside of a traditional Blog,
        because you can’t add fields like “Date and Time, or Location” to a Blog
        Post. You also can’t edit or remove existing fields like “Blog Post
        Title,” or “Blog Featured Image,” both of which might not be relevant in
        the case of an Events Listing use case.
      </Text>

      <iframe
        title="loom"
        className="iframe-responsive"
        width="100%"
        height="100%"
        display="flex"
        src="https://www.loom.com/embed/1fef0caffa704a3db055237a2577b631"></iframe>
      <Text></Text>
      <Text>
        b.) Use our database tool, HubDB, to create an “Events” table, but then
        need support from a Developer or HubSpot Partner to create the website
        pages and connect them to the HubDB table, which requires the Developer
        to write code using our propriety language, HubL, to fetch the relevant
        fields from the respective HubDB table. This process is painful for
        Developers and doesn’t provide the Marketer with any flexibility to make
        changes to the website pages or layouts.
      </Text>

      <iframe
        title="loom"
        className="iframe-responsive"
        width="100%"
        height="100%"
        display="flex"
        src="https://www.loom.com/embed/1bc3ff5fec9546c7bb70312f6ce30b6f"></iframe>

      <H2 left>Research</H2>

      <Text>
        We recruited Marketers and Content-Creators for the first round of
        research, but we didn’t get the full picture, so in our second round of
        research, we focused on adopting triangulation by recruiting developers,
        marketers, and content creators to get to more clear on the pain areas.
      </Text>

      <Text>
        First, we spoke with 6 customers who have been using our Blog tool for
        other use cases like Events, Case Studies, Knowledge Base Articles, and
        more. We also presented our latest concepts for the future of structured
        content, with the goal of using these customer insights to refine our
        2022 roadmap for HubDB and structured content.
      </Text>

      <img className="detail-image" src="quotes1.png" alt="HubDB Walkthrough" />

      <Text>
        Then, we spoke with 6 customers about their current experience with
        HubDB to further understand where the areas of improvement are. We also
        presented our latest concepts for the future of structured content, with
        the goal of using these customer insights to refine our 2022 roadmap for
        HubDB and structured content.
      </Text>

      <img className="detail-image" src="quotes2.png" alt="HubDB Walkthrough" />

      <Text>
        {' '}
        Ultimately, learning from these separate customer personas helped give
        us a greater understanding of the problems of each of these tools so
        that we can marry those pain points and solve them in one comprehensive
        solution that satisfied the needs of both cohorts of customers.
      </Text>

      <H2 left>The Data: HubDB Generates 20% More MRR</H2>

      <Text>
        Managing data in silos is challenging, and customers have told us this
        directly. From a recent research study that the HubSpot Market Research
        team conducted: 46% of surveyors stated that they faced challenges
        managing inconsistent data from different sources. 45% stated they were
        left with unreliable or missing data, and 45% stated that it was
        difficult to connect website performance to business results.
      </Text>

      <Text>
        There’s a lot of evidence to suggest that lowering the barriers of entry
        for non-technical users to manage their data-driven content will help
        Hubspot and our customers grow and scale better. For instance, customers
        that have large websites (100+ pages) that also use HubDB bring in
        approximately 20% more $MRR (see chart below).
      </Text>

      <Text>
        These are clear signals that our work to provide an improved structured
        content solution will bring value to our customers in an evolving
        digital landscape where websites are no longer digital brochures - they
        are sophisticated and personalized experiences.
      </Text>

      <img className="detail-image" src="chart.png" alt="Hubspot 5" />
      <Text>
        This is important because it reveals that customers who leverage HubDB
        are more likely to grow with us, and invest in our platform. As we make
        data-driven content more accessible to a wider customer base, we should
        see $MRR increase, as more non-technical customers adopt data-driven
        content tooling.
      </Text>

      <H2 left>The Big Opportunity</H2>

      <Text>
        How might we make it easy for customers to create a custom type of
        structured content? What do Marketers care about when it comes to
        creating these types of data-driven and personalized experiences? These
        are the types of questions that my team and I discussed in workshops, on
        customer calls, and with other teams and product groups. It helped us
        prioritize the most important aspects of this new experience that we
        would plan to build that would replace our existing database tool,
        HubDB.
</Text>
<Text>
        What if HubDB was a much more user-friendly experience for our Marketers and Content Creators?
         Many of the discussions the team and I (and customers) have had over the last few quarters
          have pointed to this opportunity as one that could be quite valuable for our Retail 
          and Enterprise customers. Our latest thinking has been that we should consider
           rebranding or repositioning HubDB as “Custom Content Types,” which sounds
            much more approachable to non-technical customers. 
            This opportunity was one that the team and I explored deeply through workshops and customer calls to converge on a final solution.
      </Text>

      <Text>
        I ran a visioning workshop further generate ideas, group them, and then
        prioritize them accordingly, with my team, and a few customers that were
        interested in participating.
      </Text>

      <img className="detail-image" src="exercises.png" alt="Hubspot 5" />

      <Text>
        We generated a lot of ideas and concepts from our divergent-thinking /
        visioning workshop. Many of these concepts were things that I was able
        to set foundations for and plant seeds so that HubSpot could have a head
        start when these respective ideas became priorities within the context
        of the company.
      </Text>
      <H2 left>The Solution: Custom Content Types</H2>

      <Text>
        Ultimately, we decided to focus on improving HubDB today and make it a more user-friendly
        experience for our Marketers and Content Creators, who are our primary
        personas. We had many discussions and challenges while converging on a
        solution. We ended up deciding that moving forward with rebranding HubDB
        as “Custom Content Types,” was the right strategy after we continued to
        iterate on the concepts and gathered more feedback from customers (Marketers, Developers, Partners and Content Creators).
      </Text>
      <Text>
        Eventually, we would bring this object onto the Framework - but in the
        meantime, it would still be decoupled from the CRM Object Framework, so
        that we could move towards providing a more friendly content-editing
        experience. Below are some of the latest concepts that are currently in
        development.
      </Text>

      <H3 left>A Marketer-friendly view of HubDB rows:</H3>
      <img className="detail-image" src="hubspot.png" alt="Hubspot 5" />

      <H3 left>
        A Marketer-friendly authoring experience for HubDB instance pages:
      </H3>
      <img className="detail-image" src="hubspot5.png" alt="Hubspot 5" />

      <H3 left>A Marketer-friendly editing experience for Dynamic Pages:</H3>
      <img className="detail-image" src="hubspot7.png" alt="Hubspot 5" />

      <H3 left>Figma Prototype:</H3>
      <H3 left> </H3>

      <iframe
        title="figma"
        className="iframe-responsive"
        width="100%"
        height="100%"
        display="flex"
        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.co
m%2Fproto%2FhJAAT3Ioi1uajAUWTGxHXe%2FCustom-Content-Types%3Fpage-id%3D0%253A1%26
node-id%3D0%253A483%26viewport%3D241%252C48%252C0.03%26scaling%3Dmin-zoom
%26starting-point-node-id%3D0%253A483"
        allowfullscreen></iframe>

      <H2 left>Delivering on the Vision</H2>

      <Text>
        After garnering a lot of excitement and alignment around the UX North
        Star Vision (the Figma prototype), I worked with my PM and Engineering
        Lead to start figuring out which features and functions of the UX Vision
        should be built first and who would be responsible for building them. We
        ended up creating three milestones (see below) that would help us get to
        this new world of “Custom Content Types.”
      </Text>

      <img className="detail-image" src="milestones.png" alt="Hubspot 5" />

      <H2 left>Data Unification and Why it Matters</H2>

      <Text>
        When a company scales its content management system into a content
        platform, or digital experience platform, two things come to mind that I
        think become more important: data governance + unification, and building
        features that scale really well. 
        </Text>
        <Text>

        Our team's philosophy has embodied
        these two principles - we want to build a future where customers can use
        one Custom Content Type (ex: Events), to power their web experiences,
        email campaigns, social media campaigns, ads, and more. I believe that
        the future of content platforms are headless, and decoupled from their
        presentation assets. 
      </Text>

      <img className="detail-image" src="thing.png" alt="Hubspot 5" />

      <H2 left>The "Data" Layer</H2>

      <Text>
        I've learned that the "Data" layer of any no-code solution 
        must be flexible and support querying data from multiple data sources 
        in order to support many different types of use cases. Implementing
        a more flexible and powerful Data layer was among one of the most 
        important conversations that I was involved in during my time at HubSpot.
        </Text>

        <Text>
        This taught me that, as a designer, I must in lock-step with engineering,
        and fully understand what the limitations of our current technology are,
        and what adopting a new technology would enable us to do for users. Below is 
        an example of one artifact that we created to show what our new Data layer could look like
        assuming we adopted GraphQL.

        <img className="detail-image" src="data-layer.png" alt="datalayer" />


      </Text>

      <H2 left>Final Thoughts</H2>

      <Text>
        When building complex products: it’s imperative to hone in on the importance of balancing the need to
        build more features with the need to limit complexity that creates a
        less desirable user experience. This is a natural progression for any
        enterprise platform; the more features you build, the more complex the
        platform becomes for its non-technical users. We instilled this as a team value for the future
        of Structured Content at Hubspot by focusing on improving the
        usability of an existing features, rather than introducing more
        complexity. 
      </Text>

      <Text>
        I’ve had an awesome time digging into this particular problem at HubSpot
        - it’s a highly complex, but rewarding challenge that requires a lot of
        patience and strategic thinking. I am highly confident that if HubSpot
        delivers on this future vision I set forth, it will pay in dividends
        with regards to increasing conversion rates for Freemium customers, and
        will improve the well-being of our Enterprise customers who use HubDB
        today.
      </Text>

      <img className="detail-image" src="nocode.png" alt="Hubspot 5" />

      <div className="button-center">
        <ButtonLinkSecondary path="/">Back to all work</ButtonLinkSecondary>
      </div>
    </div>
  );
}
