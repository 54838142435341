function Footer() {
  return (
    <div className="footerthing">
      <span>Made with 💙 by Ramzi &</span>
      <a href="https://www.mattwojtkun.com/" target="_blank" rel="noreferrer">
        {' '}
        Wojo
      </a>
    </div>
  );
}

export default Footer;
