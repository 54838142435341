import './Detail.css';
import H2 from '../../components/h2/H2';

export default function NewThink() {
  return (
    <div className="detail-container">
      <H2>Hatch Apps</H2>
      <img className="detail-image" src="hatchapps.png" alt="NewThink 1" />
      <H2 left>Overview</H2>
    </div>
  );
}
