import './Detail.css';
import H1 from '../../components/h1/H1';
import H2 from '../../components/h2/H2';
import Text from '../../components/text/Text';
import ButtonLinkSecondary from '../../components/button-link-secondary/ButtonLinkSecondary';

export default function Cms() {
  return (
    <div className="detail-container">
      <H1>Center for Medicare Services</H1>
      <img
        className="detail-image"
        src="https://pro2-bar-s3-cdn-cf4.myportfolio.com/4a4e2aff25b75908adf055a5e9feb9f7/56e9b4a6-9192-4024-8f10-9d52b78b9e9f.png?h=5989e310e09cf1b6d5fca86e05f3c3e0"
        alt="CMS 1"
      />

      <H2 left>Overview</H2>
      <Text>
        I had the opportunity to work with the Center for Medicaid and Medicare
        Services through Fjord, Accenture's Design and Innovation Studio. Most
        of my involvement on this project was focused around ideation,
        prototyping, storyboarding, and conducting several user interviews. The
        goal was to help CMS build a web platform that would allow providers to
        easily view, resolve, and submit claims.
      </Text>
      <Text>
        For this project, I utilized the Double-Diamond Design Approach:
        applying divergent and convergent thinking to arrive at the best
        solution possible to address user needs and pain points. Here is a quick
        illustration of the process I used for both the discovery and definition
        phases of this project.
      </Text>

      <img
        className="detail-image"
        src="https://pro2-bar-s3-cdn-cf5.myportfolio.com/4a4e2aff25b75908adf055a5e9feb9f7/6b26107e-d650-4c77-bfdb-da9b0b1523a8.png?h=e46baa049c6a8ed8276efe4f2af9551b"
        alt="CMS 2"
      />

      <H2 left>User Interviews</H2>
      <Text>
        We conducted over 30 user interviews with CMS providers to learn more
        about what the pain points that they had with submitting and resolving
        claims for CMS patients. We learned that most providers were struggling
        with their claims management systems, and the frustrated with the
        process of resolving claims that had been denied by CMS.{' '}
      </Text>
      <Text>
        One pain point that we heard several times from providers was that CMS's
        denial codes were difficult to decode and quickly understand. This
        required a provider to google search CMS codes to figure out the reason
        for the denial. The challenge is that all of CMS's denial codes live in
        a .pdf that is rarely updated. So, when CMS mails a letter to a
        particular provider with a denial code, and no clear reason for the
        denial, providers' are put in a difficult situation which requires them
        to spend countless hours browsing the internet for denial code
        explanations, and getting on the phone with CMS's customer support line.{' '}
      </Text>
      <Text>
        After completing our user interviews, I led facilitation of pain point
        affinity mapping, and using those pain points to identify opportunities
        that could help CMS providers have all the information in one cohesive
        place. We landed on the idea of creating a lightweight web application
        that would give CMS providers a platform to search for denial code
        explanations, see updates to specific coverage requirements, file new
        claims, resolve outstanding claims, and chat with the CMS support line.
      </Text>

      <H2 left>Initial Wireframes</H2>
      <Text>
        My work for CMS started with the notion that hospitals that provide
        Medicare services do not have a platform to easily manage patient
        claims, and that there are currently several platforms the user has to
        access in order to close out a claim. The wireframes below provided a
        solution to this by pulling the data from other systems in to a
        dashboard view.
      </Text>

      <img
        className="detail-image"
        src="https://pro2-bar-s3-cdn-cf4.myportfolio.com/4a4e2aff25b75908adf055a5e9feb9f7/f48e15d2-2366-407c-864e-6bc3851e503b.png?h=abea78273a26f993994a55eb00b0051e"
        alt="CMS 3"
      />

      <H2 left>UI Design</H2>
      <Text>
        After numerous user interviews and testing in lo-fidelity, I designed
        the portal modules in a higher-fidelity to present to stakeholders and
        the rest of the design team.
      </Text>

      <img
        className="detail-image"
        src="https://pro2-bar-s3-cdn-cf2.myportfolio.com/4a4e2aff25b75908adf055a5e9feb9f7/d4b56b5d-38a6-4b91-bdec-2d2d36cb6ddd.png?h=90998223c17df2ca4703ae65465367c9"
        alt="CMS 4"
      />

      <img
        className="detail-image"
        src="https://pro2-bar-s3-cdn-cf.myportfolio.com/4a4e2aff25b75908adf055a5e9feb9f7/8cb4a401-3159-4d72-8e9e-44681635d915.png?h=0b588d6d130ddff974871808facf5073"
        alt="CMS 5"
      />

      <img
        className="detail-image"
        src="https://pro2-bar-s3-cdn-cf6.myportfolio.com/4a4e2aff25b75908adf055a5e9feb9f7/7f13d0b1-6f30-4c07-8217-9945e916965c.png?h=20b79c6a6e6904ff7a9789b61f900dec"
        alt="CMS 6"
      />

      <img
        className="detail-image"
        src="https://pro2-bar-s3-cdn-cf1.myportfolio.com/4a4e2aff25b75908adf055a5e9feb9f7/c92244d3-d36a-4558-9588-a2386950c8af.png?h=2749f32781096722cfe1f0c54fe09958"
        alt="CMS 7"
      />

      <H2 left>Shipping product</H2>
      <Text>
        There are a series of deliverables that I provide to the development
        team before a feature or product gets built. I typically build my
        interactive prototypes using inVision, but have used Figma and Axure in
        the past as well. I mostly use Zeplin or Sympli to write up detailed
        specifications documents that engineering will use for any business
        logic questions that are not as obvious by just clicking through an
        interactive prototype.{' '}
      </Text>
      <Text>
        I've found that providing support and maintaining collaboration to be
        the most important factors in the success of shipping a product that is
        bug-free and highly usable and delightful. I've provided an illustration
        below of the deliverables that I provide to engineering at the beginning
        of a build sprint.
      </Text>

      <img
        className="detail-image"
        src="https://pro2-bar-s3-cdn-cf2.myportfolio.com/4a4e2aff25b75908adf055a5e9feb9f7/daea71b4-aaa5-4beb-8598-2dfacdbe3c13_rw_3840.png?h=1aaf22757fbab56e5b855feee7f0059f"
        alt="COM 8"
      />

      <div className="button-center">
        <ButtonLinkSecondary path="/">Back to all work</ButtonLinkSecondary>
      </div>
    </div>
  );
}
