const aboutPeople = [

  {
    name: 'William Goodrich',
    imgSrc: 'will-goodrich.jpeg',
    title: 'Senior Engineering Manager at HubSpot',
    description:
      'I had the pleasure of working closely with Ramzi within HubSpot’s content platform group. I watched Ramzi grow to become a key thought leader, able to push forward the vision for the CMS as a platform. Ramzi did a great job of helping develop visionary ideas and then turning those ideas into concrete visual representations to drive vision alignment across the group. Ramzi displayed an exceptional ability to understand complicated technical capabilities and demonstrate how those could be used to deliver customer value. Hope I have the opportunity to work with Ramzi again in the future.',
  },
  
  {
    name: 'William Spiro',
    imgSrc: 'will-spiro.jpeg',
    title: 'Senior Product Manager at HubSpot',
    description:
      'Ramzi is an absolutely phenomenal team member due to his kind nature, strong UX abilities, and desire to solve for the customer. Ramzi thrives on customer feedback and helps align the team around top priority problems through clear problem definitions and incredible UX visions. Additionally, Ramzi is an amazing storyteller and uses real customer problems and UX artifacts to help illuminate the impact of work, create alignment and ultimately execute on building great products. Ramzi understands how to create business and customer impact, and is a joy to work with. I hope our paths cross again in the future!',
  },
  {
    name: 'Peter Hershey',
    imgSrc: 'peter-hershey.jpeg',
    title: 'Interaction Designer at Google',
    description:
      'Ramzi is a strategic thinker with a keen eye for design. At Fjord, we worked closely together on high-priority projects that often had quick turnaround times. He always impressed me with how quickly he was able to create thoughtful design solutions for client problems. Beyond his impressive design chops, Ramzi is also the best kind of teammate—a pleasure to work with and always on his A game. As a human being and a product designer, he always inspires me to step up my game.',
  },

  {
    name: 'Ariel van Spronsen',
    imgSrc: 'ariel-spronsen.jpeg',
    title: 'Senior Content Designer at HubSpot',
    description:
      'I had the pleasure of working with Ramzi during my early days at HubSpot. He was a natural collaborator, instinctively understanding what teaming up with a content designer could offer to our product design endeavor. He enthusiastically included me in the problems he was thinking through. Not only did this make me feel welcomed, it also sparked my thinking about structured and data-driven content, and how those could push the HubSpot product forward. On top of that, his design work is meticulous and well thought-out, and his communication skills are tops. I feel super grateful to have gotten to work with him for a bit, and would do it again in a heartbeat.',
  },
  {
    name: 'Amelia DeSorrento',
    imgSrc: 'amelia.jpeg',
    title: 'People Strategy at Capital One | Forbes 30 Under 30',
    description:
      'Ramzi was the first person I ever hired, and man did I get lucky with him. Ramzi is a talented designer— he can own the full design stack, taking projects through user interviews and prototypes all the way to handoff-ready high-fidelity designs. He was willing to take on all of our design needs, from product design to marketing website design to graphic design. Ramzi delivers high-quality product quickly and always with a positive attitude. I’m so grateful to have had the opportunity to work with him, and I’ll look forward to our next collaboration.',
  },
  {
    name: 'Earl Anthony Grey',
    imgSrc: 'earl.jpeg',
    title: 'Cheif of Staff at Defense Digital Service',
    description:
      'Profound, efficient, talented, creative, and determined. Just a few words that describe Ramzi as an individual. I had the absolute pleasure of working with Ramzi as part of the Accenture/Fjord D.C. studio and his natural gift as an Interaction Designer was immediately apparent. His ability to produce beautiful and thought provoking-work, charismatically engage any audience (from business stakeholders to end users), and serve as a supportive partner and leader on any given project, embodies the true definition of an experience designer and a design thought leader. Ramzi is a rare talent with an immensely bright future and I sincerely look forward to the day that I am given another chance to work alongside him.',
  },
  {
    name: 'Benjamin Price',
    imgSrc: 'ben.jpeg',
    title: 'Founder of Open Sats',
    description:
      'Ramzi handled every single design oriented aspect of our software portfolio, from client logos, website makeovers, new product development, all the way to advanced UX/UI of native applications. As the product Manager, we were forced to be quick, nimble, and always deliver on time. Ramzi time and time again delivered, whether it was a 3 day sprint or a 3am urgent request. I cannot put into words how valuable it was to have a reliable and consistent designer with Ramzi’s work ethic and eye for the right design. He’s an invaluable asset to any team, and one day I hope to work alongside Ramzi again. I wholeheartedly recommend him as a designer and team member for anyone looking for a creative mind on a nimble team, regardless of project size and scope',
  },
  {
    name: 'Jordan Wahbeh',
    imgSrc: 'jordan.jpeg',
    title: 'Investor at Bay Angels VC',
    description:
      'I had the extreme pleasure to work with Ramzi, and engaged with as Part of Bay Angels, and other capacities. Ramzi is driven, balanced, and clear-minded about projects and the future. He has excellent leadership skills, an eye for design, and technical knowledge that I havent encountered in other designers. I was especially impressed with his ability, patience, and a keen interest in mentoring junior designers. I wholeheartedly recommend Ramzi for a creative leadership opportunity at your organization.',
  },
  {
    name: 'Sarah Xu',
    imgSrc: 'sarah.jpeg',
    title: 'Growth Lead at Circle Medical',
    description:
      'Ramzi is such a joy to work with. He is collaborative, growth-minded, and genuinely kind. In stressful situations, Ramzi rises to the challenge and never hesitates to take on additional roles or learn new skills for the benefit of the team.',
  },
];

export default aboutPeople;
